
.App{
  height: 100vh;
  background: #dcdcdc;
}
.App-header{
  height: 100%;
  /* background: url('./images/TFC-Store-Closing-banner-Desktop-v1.jpg'); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} 

.App img{
  width: 100%;
}
/* @media screen and (max-width: 1024px){
  .App-header{
    height: 1366px;
    background: url('./images/TFC-Store-Closing-banner-Mobile-v1.jpg');
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
  }
} 

@media screen and (max-width: 768px){
  .App-header{
    height: 100vh;
    background: url('./images/TFC-Store-Closing-banner-Mobile-v1.jpg');
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
  }
} 

@media screen and (max-width: 500px){
  .App-header{
    height: 100%;
    background: url('./images/TFC-Store-Closing-banner-Mobile-v1-500.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }
} 

@media screen and (max-width: 400px){
  .App-header{
    height: 100%;
    background: url('./images/TFC-Store-Closing-banner-Mobile-v1-400.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }
} 

@media screen and (max-width: 320px){
  .App-header{
    height: 812px;
    background: url('./images/TFC-Store-Closing-banner-Mobile-v1-320.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
  }
}  */